<script lang="ts" setup>
const props = defineProps({
  qty: {
    type: Number,
    default: 1,
  },
  max: {
    type: Number,
    default: 0,
  },
  itemId: {
    type: Number,
    default: 0,
  },
  updateCart: {
    type: Function,
    default: null,
  },
});
const emit = defineEmits(['update:qty']);
const count = ref<number>(props.qty);

const handleClick = (type: any) => {
  if (type) {
    if (count.value === props.max) return;
    count.value++;
  } else {
    if (count.value === 1) {
      return false;
    } else[count.value--];
  }
};

watch(count, (newValue) => {
  if (newValue === props.qty) return;
  if (props.updateCart) {
    props.updateCart(props.itemId, newValue)
  }
  emit('update:qty', newValue);
});
</script>

<template>
  <div class="count">
    <button class="buttonReset subtract" @click="handleClick(0)"></button>
    <div class="count__number">{{ count }}</div>
    <button class="buttonReset add" @click="handleClick(1)"></button>
  </div>
</template>

<style lang="scss" scoped>
.count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 200px;
  height: 40px;
  border: 1px solid #000;

  &__number {
    width: 30px;
    font-family: $font-NotoSans;
    font-size: 16px;
    text-align: center;
    color: rgba(#000, 0.8);

    @include max-media(960) {
      font-size: 12px;
    }
  }

  button {
    position: relative;
    flex: 1;
    height: 40px;
  }

  .subtract,
  .add {
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -5px;
      margin-top: -0.5px;
      width: 10px;
      height: 1px;
      background-color: #000;
    }
  }

  .add {
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -5px;
      margin-left: -0.5px;
      width: 1px;
      height: 10px;
      background-color: #000;
    }
  }
}
</style>
